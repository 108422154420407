import React from 'react';
import {graphql, useStaticQuery, Link} from 'gatsby';
import Img from "gatsby-image";
import Layout from '../../components/layout';
import Intro from '../../components/intro';
import ProjectQuote from '../../components/project-quote';
import ProjectMeta from '../../components/project-meta';
import Logo from '../../assets/logoCRES.svg';
import LogoAzure from '../../assets/microsoft-azure.svg';
import LogoSQL from '../../assets/microsoft-sql-server.svg';
import LogoWebAPI from '../../assets/microsoft-dot-net.png';
import LogoAngular from '../../assets/angular.svg';
import LogoWordpress from '../../assets/wordpress.svg';
import ProjectWrapper from '../../components/project-wrapper';
import ProjectNav from '../../components/project-nav';
import NavArrow from '../../assets/navArrow.svg';

const Work = () => {
  const {screenHome, mobileHome, screenDashboard} = useStaticQuery(graphql`
    query {
      screenHome: file(relativePath: { eq: "cres/screenHome.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileHome: file(relativePath: { eq: "cres/mobileHome.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screenDashboard: file(relativePath: { eq: "cres/screenDashboard.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout page="Our Work">
      <Intro>
        <h2>Enabling small local businesses to supply goods and services to BHP</h2>
        <p>
          The <strong>Local Buying Program</strong> was established to support small businesses to engage with BHP and is
          delivered in a strategic partnership between BHP and C-Res.
        </p>
      </Intro>

      <ProjectWrapper>
        <div className="project">
          <h3>The Project</h3>
          <p>
            The <strong>Local Buying Program</strong> is a complex application that has been developed over many years. 
            In a nutshell, it involved the design and development of a system to provide small businesses the opportunity
            to register and quote on BHP work opportunities via a competitive tender process.
          </p>
          <p>
            The platform has been successfully operating since 2012 and has so far delivered close to $400M to small 
            businesses and local communities across Australia.
          </p>
        </div>

        <img src={Logo} alt="C-Res logo" className="logo" />

        <div className="solution">
          <h3>The Solution</h3>
          <p>We worked closely with the C-Res team from day one, to design and develop a complete end-to-end solution covering:</p>
          <ul>
            <li>Supplier Registration and Management</li>
            <li>Buyer Registration and Management</li>
            <li>Work Instruction Management</li>
            <li>Claim Management</li>
            <li>Automated Business Processes</li>
            <li>Reporting and Compliance</li>
            <li>Systems Integration with MYOB and SAP Ariba</li>
          </ul>

          <p>We also manage the day to day operations of the platform including:</p>
          <ul>
            <li>Cloud Hosting</li>
            <li>System Backups and Monitoring</li>
            <li>Performance Tuning and Optimisation</li>
            <li>Feature Enhancements</li>
            <li>Support and Maintenance</li>
            <li>Security Analysis and Review</li>
          </ul>
        </div>

        <div className="screens">
          <Img fluid={screenHome.sharp.fluid} fadeIn alt="C-Res Home page" />
          <Img fluid={screenDashboard.sharp.fluid} fadeIn alt="C-Res Dashboard page" />
        </div>

        <ProjectMeta>
          <div className="requirements">
            <h3>The Development</h3>
            <p>The key areas of development for this project included:</p>
            <ul>
              <li>Database Design and Development</li>
              <li>Web Application Development</li>
              <li>Systems Integration</li>
              <li>Wordpress Development</li>
              <li>UX Design</li>
              <li>Responsive Design</li>
              <li>Progressive Web Application</li>
            </ul>
          </div>
        </ProjectMeta>

        <Img fluid={mobileHome.sharp.fluid} fadeIn alt="" className="mainImage" />

        <ProjectMeta>
          <h3 className="techHeading">The Technology</h3>
          <div className="techLogos">
            <img src={LogoAzure} alt="Microsoft Azure Cloud logo" />
            <img src={LogoSQL} alt="Microsoft SQL logo" />
            <img src={LogoWebAPI} alt="Microsoft WebAPI logo" />
            <img src={LogoAngular} alt="Angular logo" />
            <img src={LogoWordpress} alt="Wordpress logo" />
          </div>
        </ProjectMeta>

        <ProjectQuote>
          The team at Talen are very professional and have helped us build a unique community program that everyone is
          extremely happy with. They provide great support and are a pleasure to work with.
        </ProjectQuote>

        <ProjectNav>
          <div></div>
          <div className="next">
            <Link to="/work/bhp">BHP</Link>
            <img src={NavArrow} />
          </div>
        </ProjectNav>
      </ProjectWrapper>
    </Layout>
  );
};

export default Work;
